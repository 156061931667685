import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";
import {ROUTES} from "../routing";
import {connectUser} from "../connectors/user";


function TopBar (props) {

    const [buttonPath, buttonText, extraText] = (pathname => (pathname === ROUTES.login || pathname === ROUTES.password_reset) ?
        [ROUTES.register, 'Register', 'Need an account?'] :
        pathname === ROUTES.register ?
            [ROUTES.login, 'Sign In', 'Already registered?'] :
            [ROUTES.login, 'Log Out', `Welcome ${props.user.displayName}`]
    )(props.history.location.pathname);
    const buttonProps = {
        color: 'secondary',
        fullWidth: true,
        onClick: () => {
            props.history.push(buttonPath);
            props.end_session();
        },
        variant: 'contained'
    };

    return <Box boxShadow={3} p={1}>
        <Grid container justify="space-between" direction="row" alignItems="center">
            <Grid item xs={3}>
                <img width="200px" alt="CosmosID" src="/CosmosID-Hub_COVID-19.png"/>
            </Grid>
            <Grid item xs={6}>
                <Typography align="center" variant="h4" display="block">
                    SARS-CoV-2 Strain Typing Analysis Portal
                </Typography>
            </Grid>
            <Grid item xs>
                <Typography align="center" variant="body2">{extraText}</Typography>
            </Grid>
            <Grid item xs>
                <Button {...buttonProps}>{buttonText}</Button>
            </Grid>
        </Grid>
    </Box>
}

export default withRouter(connectUser(TopBar));
