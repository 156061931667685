import React, {Fragment, useEffect} from "react";
import {CenteredLabel, ReportTable} from "./Common";
import axios from "axios";


export default function (props) {

    return (
        <Fragment>
            <CenteredLabel>
                <b>Table 1: Coverage Statistics</b> for the SARS-CoV-2 genome.
            </CenteredLabel>
            <ReportTable headers={[
                'Reference Sequence',
                'Chromosome',
                'Chr Start',
                'Chr End',
                'Num Amplicons',
                'Covered Bases',
                'Target Bases',
                'Missed Bases',
                'Average Base Coverage',
                'Uniformity Base Coverage',
                '% Reads on Target',
                'Target Coverage',
            ]} values={
                props.covStatData ?
                props.covStatData.map(rowData => (
                    [
                        rowData.ref_seq,
                        rowData.chromosome,
                        rowData.chr_start,
                        rowData.chr_end,
                        rowData.num_aplicons,
                        rowData.covered_bases,
                        rowData.target_bases,
                        rowData.missed_bases,
                        rowData.avg_b_coverage,
                        rowData.uni_b_coverage,
                        rowData.reads_on_target,
                        rowData.target_coverage
                    ]
                )) :
                null
            }/>
        </Fragment>
    )
}