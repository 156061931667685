import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom"
import {connect} from "react-redux";
import {authenticate, getOwnUser, refreshToken} from "../actions";
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

function Login (props) {
    const classes = useStyles();
    useEffect(() => {
        if (props.jwt_access) props.getOwnUser();
    }, [props.jwt_access])
    useEffect(() => {
        if (!props.jwt_access && props.jwt_refresh) props.refreshToken();
    }, [props.jwt_refresh])
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    return (
        <div className={classes.root}>
            <Grid container alignItems="center" justify="center" style={{ minHeight: '20vh'}}>
                <Grid item xs={7}>
                    <Typography variant="h5" style={{ minHeight: '20vh', display: "none" }}>
                        The CosmosID SARS-CoV-2 Genome Analysis Portal automates the analysis of data generated with the Ion AmpliSeq™ SARS-CoV-2 Research Panel by Thermo Fisher
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" justify="center" style={{ minHeight: '20vh' }}>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        {
                            showForgotPassword ?
                            <form name="forgot-password-form">
                                <Grid container alignItems="stretch" justify="center" direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography variant="h4" color="textPrimary">Reset Password</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField name='email' fullWidth label="Work email" required={ true }/>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography onClick={() => setShowForgotPassword(false)}
                                                    variant="body2"
                                                    align="left"
                                                    color="primary"
                                        >Go back to Sign In
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="flex-end" justify="center" direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Button variant="contained" color="primary" onClick={
                                            (event) => axios.post(
                                                `/api/v1/reset_password_request/`,
                                                {
                                                    email: event.currentTarget.form['email'].value
                                                }
                                            ).then(() => {
                                                setSnackbarText("E-mail with further instructions has been sent");
                                                setOpenSnackbar(true)
                                            })
                                        }>
                                            Reset Password
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form> :
                                window.location.pathname === '/password_reset' ?
                                    <form name="password-reset-form">
                                        <Grid container alignItems="stretch" justify="center" direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography variant="h4" color="textPrimary">New Password</Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <TextField name='password' fullWidth label="Password" required={ true } type={"password"}/>
                                            </Grid>
                                            <Grid item xs>
                                                <TextField name='passwordConfirm' fullWidth label="Confirm Password" required={ true } type={"password"}/>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography onClick={() => props.history.push('/login')}
                                                            variant="body2"
                                                            align="left"
                                                            color="primary"
                                                >Go back to Sign In
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="flex-end" justify="center" direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Button variant="contained" color="primary" onClick={
                                                    (event) => {
                                                        const form = event.currentTarget.form;
                                                        if (form.password.value !== form.passwordConfirm.value) {
                                                            setSnackbarText("Passwords don't match");
                                                            setOpenSnackbar(true)
                                                            return;
                                                        }
                                                        axios.post(
                                                            `/api/v1/reset_password/`,
                                                            {
                                                                password: event.currentTarget.form['password'].value,
                                                                passwordResetKey: (() => {
                                                                    const url = new URL(window.location);
                                                                    return url.searchParams.get("passwordResetKey")
                                                                })()
                                                            }
                                                        ).then(() => {
                                                            setSnackbarText("Your password has been changed");
                                                            setOpenSnackbar(true);
                                                            form.reset();
                                                            setTimeout(() => props.history.push('/login'), 5000);
                                                        })
                                                    }}>
                                                    Set Password
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form> :
                            <form name="login-form">
                                <Grid container alignItems="stretch" justify="center" direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography variant="h4" color="textPrimary">Sign In</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField name='email' fullWidth label="Work email" required={ true }/>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField name='password' fullWidth label="Password" required={ true } type="password"/>
                                        <Typography onClick={() => setShowForgotPassword(true)}
                                            variant="body2"
                                            align="left"
                                            color="primary"
                                        >Forgot password?
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="flex-end" justify="center" direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Button variant="contained" color="primary" onClick={
                                            (event) => props.authenticate(event, props.history, {
                                                setSnackbarText, setOpenSnackbar
                                            })
                                        }>
                                            Sign In
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        }

                    </Paper>
                </Grid>
                <Snackbar
                anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarText}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpenSnackbar(false)}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }/>
            </Grid>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        jwt_access: state.jwt_access,
        jwt_refresh: state.jwt_refresh
    }
};


const mapDispatchToProps = dispatch => {
    return {
        authenticate: (e, history, snackbarSetters) => {
            const form = e.currentTarget.form;
            const name = form['email'].value;
            const password = form['password'].value;
            dispatch(authenticate(name, password, history, snackbarSetters));
        },
        getOwnUser: () => dispatch(getOwnUser()),
        refreshToken: () => dispatch(refreshToken())
    }
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Login)
);