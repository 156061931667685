import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, {Fragment} from "react";
import {SimpleLabel} from "./Common";


export default function ReportHeader(props) {
    const today = new Date();
    return (
        <Fragment>
            <Grid item xs={9}>
                <Typography variant={"h5"}>Analysis Report</Typography>
                <div>
                <SimpleLabel>
                    <b>Data Uploaded By:</b> {props.userId}<br/>
                    <b>File:</b> {props.sampleId}.bam
                </SimpleLabel>
                </div>
            </Grid>
            <Grid item xs={2}>
                <img src="/cosmosid_logo.png" style={{'width': '100%'}} alt='CosmosID'/>
                <SimpleLabel><b>Date:</b> { today.toLocaleDateString("en-US") }</SimpleLabel>
            </Grid>
        </Fragment>
    )
}