import React, {useEffect, useState} from "react";
import MaterialTable, {MTableToolbar} from "material-table";
import axios from 'axios';
import {connectUser} from "../connectors/user";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import {useDispatch} from "react-redux";
import {redeemCode} from "../actions";
import {Chip} from "@material-ui/core";


const columns = [
    {
        title: 'Code',
        field: 'code'
    },
    {
        title: 'Credits',
        field: 'value'
    },
    {
        title: 'Redeemed',
        field: 'redeemed'
    },
    {
        title: 'Redeemed By',
        field: 'redeemer_email'
    }
]

const generateCodeAction = (callback, generated_by, value) => ({
    icon: () => (
        <span>
            <AddIcon/>
            {value}
        </span>
    ),
    tooltip: `Generate Code worth ${value} credits`,
    isFreeAction: true,
    onClick: () => {
        axios.post(
            `/api/v2/credit-codes/`,
            {
                generated_by,
                value
            }
        ).then(({data}) => callback(data))
    }
});

const CreditCodeValues = [96, 384];

const CreditCodesManager = props => {
    const [data, setData] = useState([]);
    const [lastCode, setLastCode] = useState(null);
    useEffect(() => {
        axios.get(`/api/v2/credit-codes/`).then(
            ({data}) => setData(data)
        )
    }, [lastCode])
    return <MaterialTable
        columns={columns}
        data={data}
        title={'Credit Codes'}
        actions={
            CreditCodeValues.map(
                value => generateCodeAction(data => setLastCode(data.code), props.user.id, value)
            )
        }
    />
};


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const CreditCodesUser = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const onRedeemError = error => {
        const errorText = error.response?.data.detail || 'Network error';
        setOpenSnackbar(true);
        setSnackbarText(errorText);
    };
    const redeemButtonOnClick = event => {
        const creditCode = event.currentTarget.form.creditCode.value;
        dispatch(redeemCode(creditCode, onRedeemError))
    };
    return (
        <div className={classes.root}>
            <Grid container alignItems="center" justify="center" style={{ minHeight: '20vh' }}>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <Typography variant={"h6"}>
                            Analysis Credits: <Typography component={"span"} variant={"h6"} color={"secondary"}>{ props.user.credits }</Typography>
                        </Typography>
                        <p>Redeem Credit Code to get more credits</p>
                        <form name="redeem-credit-code-form">
                            <Grid container alignItems="stretch" justify="center" direction="column" spacing={2}>
                                <Grid item xs>
                                    <TextField name='creditCode' fullWidth label="Credit Code" required={ true }/>
                                </Grid>
                                <Grid item xs>
                                    <Button variant="contained" color="primary" onClick={redeemButtonOnClick}>Redeem</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarText}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpenSnackbar(false)}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }/>
        </div>
    );
}


const CreditCodes = props => {
    if (props.user.isCreditCodesManager) {
        return <CreditCodesManager {...props}/>
    }
    else {
        return <CreditCodesUser {...props}/>
    }
};


export default connectUser(CreditCodes);