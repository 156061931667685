import React from "react";
import {
    Switch,
    Redirect,
    Route
} from "react-router-dom";
import Login from "./pages/Login";
import { withRouter } from "react-router-dom"
import Register from "./pages/Register";
import Upload from "./pages/Upload";
import Samples from "./pages/Samples";
import Admin from "./pages/admin";
import NavTabs from "./components/NavTabs";
import Activate from "./pages/Activate"
import {connectUser} from "./connectors/user";
import CreditCodes from "./pages/CreditCodes";

export const ROUTES = {
    activate: "/activate",
    login: "/login",
    password_reset: "/password_reset",
    register: "/register",
    upload: "/upload",
    samples: "/samples",
    admin: "/admin",
    documentation: "/documentation",
    credit_codes: "/credit-codes"
};


const AnonymousRouting = props => (
    <Switch>
        <Route path={ROUTES.activate}>
            <Activate/>
        </Route>
        <Route path={ROUTES.login}>
            <Login/>
        </Route>
        <Route path={ROUTES.password_reset}>
            <Login/>
        </Route>
        <Route path={ROUTES.register}>
            <Register/>
        </Route>
        <Route path="/">
            <Redirect to={ROUTES.login}>
            </Redirect>
        </Route>
    </Switch>
);
    
const UserRouting = props => (
    <Switch>
        <Route path={ROUTES.upload}>
            <NavTabs uppy={props.uppy}/>
            <Upload uppy={props.uppy}/>
        </Route>
        <Route path={ROUTES.samples}>
            <NavTabs uppy={props.uppy}/>
            <Samples/>
        </Route>
        <Route path={ROUTES.credit_codes}>
            <NavTabs uppy={props.uppy}/>
            <CreditCodes/>
        </Route>
        <Route path="/">
            <Redirect to={ROUTES.upload}>
            </Redirect>
        </Route>
    </Switch>  
);


const AdminRouting = props => (
    <Switch>
        <Route path={ROUTES.admin}>
            <Admin/>
        </Route>
        <Route path="/">
            <Redirect to={ROUTES.admin}>
            </Redirect>
        </Route>
    </Switch>
);

function Routing(props) {
    console.log('Router:', props.user);
    if (props.user.isGuest) return <AnonymousRouting {...props}/>;
    if (props.user.isRegularUser) return <UserRouting {...props}/>;
    if (props.user.isAdmin) return <AdminRouting {...props}/>;
}

const RoutingActive = connectUser(Routing);

export default withRouter(RoutingActive);