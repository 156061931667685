import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom"
import {connect} from "react-redux";
import {authenticate} from "../actions";
import {ROUTES} from "../routing";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

function Activate (props) {
    const classes = useStyles();
    const [ok, setOk] = useState(null);
    useEffect(() => {
        const url = new URL(window.location);
        const activationKey = url.searchParams.get('activationKey');
        axios.post(
            '/api/v1/activate/',
            {
                activationKey
            }
        ).then(() => setOk(true)).catch(setOk(false));
    }, [])
    return (
        ok ?
        <div className={classes.root}>
            <Grid container alignItems="center" justify="center" style={{ minHeight: '20vh' }}>
                <Grid item xs={7}>
                    <Typography variant="h5">
                        The CosmosID SARS-CoV-2 Genome Analysis Portal automates the analysis of data generated with the Ion AmpliSeq™ SARS-CoV-2 Research Panel by Thermo Fisher
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" justify="center" style={{ minHeight: '20vh' }}>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <form name="login-form">
                            <Grid container alignItems="stretch" justify="center" direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography variant="h4" color="textPrimary">Account activated</Typography>
                                </Grid>
                                <Grid>
                                    <Typography variant="body1">You will receive an e-mail with further information shortly<br/><br/></Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-end" justify="center" direction="column" spacing={2}>
                                <Grid item xs>
                                    <Button variant="contained" color="primary" onClick={
                                        (event) => props.history.push(ROUTES.login)
                                    }>
                                        Proceed to Sign in
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </div>
            : ok === null ?
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop> :
            <Container style={{padding: 10}}>
                <Typography style={{textAlign: "center"}} color={"secondary"} variant={"h4"}>Activation link is invalid</Typography>
            </Container>

    )
}


const mapStateToProps = state => {
    return {
        //user: {
        //    isAdmin: state.user.roles.includes('_admin'),
        //    isGuest: state.user.roles.includes('guest')
        //}
    }
};


const mapDispatchToProps = dispatch => {
    return {
        authenticate: (e, history) => {
            const form = e.currentTarget.form;
            const name = form['email'].value;
            const password = form['password'].value;
            dispatch(authenticate(name, password, history));
        }
    }
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Activate)
);