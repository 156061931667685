import React, {Fragment} from 'react';
import './App.css';
import Routing from "./routing";
import {CssBaseline} from "@material-ui/core";
import {useUppy} from "@uppy/react";
import getUppy from "./uppy";

import Container from "@material-ui/core/Container";
import {BrowserRouter} from "react-router-dom";
import TopBar from "./components/TopBar";
import Report from "./pages/Report";
import {useDispatch} from "react-redux";
import {finalizeUpload, upload_progress} from './actions'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
    palette: {
      primary: {main: '#0F2549'},
      secondary: {main: '#EFB918'},
    }
  });


function App() {
    const dispatch = useDispatch();
    const uppy = useUppy(getUppy);
    uppy.on('upload-success', (file, response) => {
        // file: { id, name, type, ... }
        // progress: { uploader, bytesUploaded, bytesTotal }
        console.log(file.id)
        dispatch(finalizeUpload(file.id))
    })
    uppy.on('upload-progress', (file, progress) => {
        // file: { id, name, type, ... }
        // progress: { uploader, bytesUploaded, bytesTotal }
        //const progressPercentage = Math.round(progress.bytesUploaded / progress.bytesTotal * 100);
        dispatch(upload_progress(file.id, progress))
    })
    return (
        <BrowserRouter>
            <CssBaseline>
            <ThemeProvider theme={theme}>
                {window.location.pathname.startsWith('/report/print/') ?
                    <Report/> :
                    <Container maxWidth={false} disableGutters={true}>
                        <TopBar/>
                        <Routing uppy={uppy}/>
                    </Container>
                }
            </ThemeProvider>
            </CssBaseline>
            
        </BrowserRouter>
    );
}

export default App;
