import { connect } from 'react-redux';
import {end_session} from "../actions";

const mapStateToProps = state => {
    return {
        user: {
            id: state.user?.id,
            email: state.user?.email,
            displayName:  state.user?.email,
            credits: state.user?.user_profile?.credits,
            isAdmin: state.user?.is_superuser,
            isRegularUser: !state.user?.is_superuser,
            isGuest: !state.user,
            isCreditCodesManager: state.user?.user_profile?.can_generate_credit_codes,
            isCreditCodesUser: state.user?.user_profile?.can_use_credit_codes,
            analysisType: state.user?.user_profile?.analysis_type.label
        }
    }
};

const mapDispatchToProps = dispatch => {
    return {
        end_session: () => {
            dispatch(end_session())
        }
    }
};

export const connectUser = connect(mapStateToProps, mapDispatchToProps);