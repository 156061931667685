import { combineReducers } from "redux";
import filesize from 'filesize';

import {
    AFTER_REGISTRATION,
    AUTHENTICATED,
    END_SESSION, SAMPLE_DOWNLOAD_REPORT, SAMPLE_SEARCH_CHANGE, SAMPLES_FETCHED, SHOW_BLOCKED, TOGGLE_SAMPLE_SELECT,
    TOGGLE_TERMS,
    UPLOAD_DONE,
    UPLOAD_PROGRESS,
    UPLOAD_START,
    USERS_RETRIEVED,
    SET_SELECTED_SAMPLES, OWN_USER_RETRIEVED, FETCHING_SAMPLES
} from "../actions";

const initialState = {
    user: null,
    admin: {
        users: []
    },
    uploads: {
    },
    terms: false,
    samples: [],
    fetchingSamples: false,
    download: null,
    selected_samples: [],
    sampleSearch: '',
    afterRegistration: false,
    showBlocked: false,
    jwt_access: null,
    jwt_refresh: localStorage.getItem('jwt_refresh')
};


const jwt_access = (state = initialState.jwt_access, action) => {
    if (action.type === AUTHENTICATED) {
        return action.jwt_access;
    }
    if (action.type === END_SESSION) {
        return null;
    }
    return state;
}

const jwt_refresh = (state = initialState.jwt_refresh, action) => {
    if (action.type === AUTHENTICATED) {
        return action.jwt_refresh;
    }
    if (action.type === END_SESSION) {
        return null;
    }
    return state;
}


function user(state = initialState.user, action) {
    if (action.type === OWN_USER_RETRIEVED) {
        return action.user
    }
    if (action.type === END_SESSION) {
        return null;
    }
    return state;
}

function eqSet(as, bs) {
    if (as.size !== bs.size) return false;
    for (var a of as) if (!bs.has(a)) return false;
    return true;
}


function users(state = initialState.admin.users, action) {

    if ( action.type === USERS_RETRIEVED ) {
        const users = action.users.map(user_doc => {
            return user_doc;
        }).filter(
            (user) => {
                return user.email !== undefined
            }
        );
        return users;
    }
    return state;
}


function uploads(state = initialState.uploads, action) {
    if (action.type === UPLOAD_START) {
        const progress = {...state};
        progress[action.fileInfo.fileId] = action.fileInfo
        return progress;
    }
    if (action.type === UPLOAD_DONE) {
        const progress = {...state};
        delete progress[action.fileId];
        return progress;
    }
    return state;
}


const admin = combineReducers({
    users
});

const terms = (state = initialState.terms, action) => {
    if (action.type === TOGGLE_TERMS) {
        return !state;
    }
    return state;
};


const setSampleSize = sample => sample.size = (
    sample.input_files.map(
        input_file => input_file.size
    ).reduce(
        (total, currentValue) => total + currentValue
    ) || 0
)


const samples = (state = initialState.samples, action) => {
    if (action.type === END_SESSION) {
        return [];
    }
    if (action.type === SAMPLES_FETCHED) {
        action.samples.forEach(setSampleSize);
        return action.samples;
    }
    if (action.type === UPLOAD_PROGRESS) {
        const samples = [...state];
        let sample = samples.find(
            sample => sample.id === action.sampleId
        )
        if (!sample) {
            sample = {
                id: action.sampleId,
                name: action.sampleName,
                input_files: [{
                    id: action.inputFileId,
                    size: action.fileSize,
                    submitted_at: action.submitted_at
                }]
            }
            samples.push(sample);
        }
        let input_file = sample.input_files.find(
            input_file => input_file.id === action.inputFileId
        )
        if (!input_file) {
            input_file = {
                id: action.inputFileId,
                submitted_at: action.submitted_at,
                size: action.fileSize
            }
            sample.input_files.push(input_file);
            setSampleSize(sample);
        }
        input_file.progress = action.progress.bytesUploaded / action.progress.bytesTotal * 100;
        sample.progress = Math.round(
            sample.input_files.map(
                input_file => input_file.progress || 0
            ).reduce(
                (total, currentValue) => total + currentValue
            ) / sample.input_files.length
        );
        return samples;
    }
    return state;
};


const download = (state = initialState.download, action) => {
    if (action.type === SAMPLE_DOWNLOAD_REPORT) {
        return action.url;
    }
    return state;
}

const selected_samples = (state = initialState.selected_samples, action) => {
    if (action.type === TOGGLE_SAMPLE_SELECT) {
        if (state.includes(action.sample_name)) {
            return state.filter(sample_name => sample_name !== action.id )
        }
        else return state.concat([action.sample_name])
    }
    if (action.type === SAMPLES_FETCHED) {
        return []
    }
    if (action.type === SET_SELECTED_SAMPLES) {
        return action.samples.map(sample => sample.id);
    }
    return state
}

const sampleSearch = (state = initialState.sampleSearch, action) => {
    if (action.type === SAMPLE_SEARCH_CHANGE) {
        return action.value;
    }
    return state;
}


const afterRegistration = (prevState = initialState.afterRegistration, action) => action.type === AFTER_REGISTRATION ? !prevState : prevState;

const showBlocked = (prevState = initialState.showBlocked, action) => action.type === SHOW_BLOCKED ? !prevState : prevState;

const fetchingSamples = (prevState = initialState.fetchingSamples, action) => {
    if (action.type === FETCHING_SAMPLES) {
        return true;
    }
    if (action.type === SAMPLES_FETCHED) {
        return false;
    }
    return prevState;
}

function debug(prevState, action) {
    console.debug(prevState, action);
    const result = reducers(prevState, action);
    console.debug(result, action);
    return result;
}

const reducers = combineReducers({
    jwt_access,
    jwt_refresh,
    user,
    admin,
    uploads,
    terms,
    samples,
    selected_samples,
    sampleSearch,
    afterRegistration,
    showBlocked,
    fetchingSamples
});

export default debug;