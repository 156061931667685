import React, {useEffect} from "react";
import {NavLink, withRouter} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {downloadReport, get_samples, sampleSearchChange, toggle_sample_select} from "../actions";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import {Box, TableCell, Tooltip, withStyles} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/styles";

const topBarContext = {
    extraText: null,
    buttonText: "Log out",
    buttonHref: "/logout"
};

export const samplesPageContext = {
    topBarContext
};

const StyledTableCell = withStyles({
    root: {
        border: 'solid 2px rgb(128, 128, 128)'
    }
})(TableCell);

const StyledHeadTableCell = withStyles({
    root: {
        fontWeight: 'bold'
    }
    }
)(StyledTableCell);




function AdminSamples(props) {
    const dispatch = useDispatch();
    useEffect(
        () => dispatch(get_samples(props.email)),
    [dispatch, props.email]);

    return (
        <Container style={{margin: 10}}>
        <Grid container direction="column">
            <Grid item>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledHeadTableCell>Batch Name</StyledHeadTableCell>
                            <StyledHeadTableCell>Sample Name</StyledHeadTableCell>
                            <StyledHeadTableCell>Upload Date</StyledHeadTableCell>
                            <StyledHeadTableCell>File Size</StyledHeadTableCell>
                            <StyledHeadTableCell>Status</StyledHeadTableCell>
                            <StyledHeadTableCell>Credits used</StyledHeadTableCell>
                            <StyledHeadTableCell>Credits on hold</StyledHeadTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        { props.samples.map(sample => {
                            return (
                            <TableRow key={sample.file_name}>
                                <StyledTableCell>{sample.batch_name}</StyledTableCell>
                                <StyledTableCell>
                                    { sample.report_available ?
                                        <NavLink to={ window.location.pathname } onClick={ () => props.downloadReport(sample.sample_name, props.email) }>{sample.sample_name}</NavLink> :
                                        sample.sample_name
                                    }
                                </StyledTableCell>

                                <StyledTableCell>{sample.upload_date}</StyledTableCell>
                                <StyledTableCell>{sample.size}</StyledTableCell>
                                <StyledTableCell>{
                                    ((sample) => {
                                        let found = null;
                                        Object.entries(props.uploads).forEach(upload => {
                                            console.log(upload, sample.file_name);
                                            if (upload[0] === sample.file_name) {
                                                found = upload;
                                            }
                                        });
                                        if (found) {
                                            return (
                                                <div style={{alignItems: 'center', alignContent: 'stretch', display: 'flex'}}>
                                                    <span style={{paddingRight: '5px'}}>Sample Upload {found[1]}%</span> <CircularProgress variant="static" color={"secondary"} value={found[1]}/>
                                                </div>
                                            )
                                        }
                                        else {
                                            return sample.status;
                                        }
                                    })(sample)
                                }</StyledTableCell>
                                <StyledTableCell>{sample.status === 'Analysis complete' ? 1 : 0}</StyledTableCell>
                                <StyledTableCell>{sample.status === 'Analysis complete' ? 0 : 1}</StyledTableCell>
                            </TableRow>
                            )
                        }) }

                    </TableBody>
                </Table>
            </Grid>
        </Grid>
            <Box pt={40}><Typography align="center" variant="h6">For Research Use Only.</Typography> </Box>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        samples: state.samples.filter(sample => sample.sample_name.search(state.sampleSearch) > -1),
        download: state.download,
        uploads: state.uploads
    }
};

const mapDispatchToProps = dispatch => {
    return {
        downloadReport: (sample_name, email) => dispatch(downloadReport(sample_name, true, email)),
        toggle_sample_select: sample_name => dispatch(toggle_sample_select(sample_name))

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSamples));