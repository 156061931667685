import React from "react";
import RegisterForm from "../components/RegisterForm";
import { withRouter } from "react-router-dom"

const topBarContext = {
    extraText: "Already registered?",
    buttonText: "Sign In",
    buttonHref: "/login"
};

export const registerPageContext = {
    topBarContext
};

function Register(props) {

    return (
        <RegisterForm/>
    )
}


export default withRouter(Register);