import React, {useEffect, useState, Fragment} from "react";
import {NavLink, withRouter} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {downloadReport, get_samples, toggle_sample_select, setSelectedSamples} from "../actions";
import Grid from "@material-ui/core/Grid";
import {Box, TableCell, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialTable from "material-table";
import MTableToolbar from "material-table/dist/components/m-table-toolbar"
import axios from "axios";
import filesize from "filesize";

const topBarContext = {
    extraText: null,
    buttonText: "Log out",
    buttonHref: "/logout"
};

export const samplesPageContext = {
    topBarContext
};

const StyledTableCell = withStyles({
    root: {
        border: 'solid 2px rgb(128, 128, 128)'
    }
})(TableCell);

const StyledHeadTableCell = withStyles({
    root: {
        fontWeight: 'bold'
    }
    }
)(StyledTableCell);

function Samples(props) {
    const dispatch = useDispatch();
    const [exampleData, setExampleData] = useState(false);
    useEffect(
        () => exampleData ? dispatch(get_samples('panel@cosmosid.com')) : dispatch(get_samples()),
    [props.uploads, exampleData]);

    useEffect(() => {
        if (Object.entries(props.uploads).length > 0) {
            window.onbeforeunload = function() {
                return "Upload is in progress. Are you sure you want to cancel it?";
            }
        }
        else window.onbeforeunload = () => null;
    }, [props.uploads])

    const columns = email => [
        {
            title: 'Batch Name',
            field: 'batchName'
        },
        {
            title: 'Sample Name',
            field: 'name',
            render: rowData => (rowData.status === 11 ?
                    <NavLink
                        to={ window.location.pathname }
                        onClick={() => window.open(
                                `/api/v2/samples/${rowData.id}/report/`
                        )}
                    >
                        {rowData.name}
                    </NavLink> :
                    rowData.name
            )
        },
        {
            title: 'Upload Date',
            field: 'upload_date',
            render: rowData => new Date(rowData.input_files[0].submitted_at).toLocaleDateString(),
            customSort: (a, b) => Date.parse(a.input_files[0].submitted_at) > Date.parse(b.input_files[0].submitted_at) ?
                1 : -1,
            defaultSort: 'desc'
        },
        {
            title: 'File Size',
            field: 'size',
            render: sample => {
                const size = sample.size || 0;
                return filesize(size);
            }
        },
        {
            title: 'Status',
            field: 'status',
            render: sample => {
                if (sample.progress) {
                    return (
                        <div style={{alignItems: 'center', alignContent: 'stretch', display: 'flex'}}>
                            <span style={{paddingRight: '5px'}}>Uploading {sample.progress}%</span> <CircularProgress variant="static" color={"secondary"} value={sample.progress}/>
                        </div>
                    )
                }
                else {
                    return sample.verbose_status;
                }
            }
        }
    ]

    return (
        <Container>
        <Grid container direction="column">
            <Grid item>
                <MaterialTable
                    editable={ exampleData ? false : {
                        onRowDelete: oldData => new Promise((resolve, reject) => {
                            axios.delete(
                                `/api/v2/samples/${oldData.id}/`
                            ).then(
                                () => {
                                    resolve();
                                    console.log('why');
                                    dispatch(get_samples());
                                }
                            ).catch(
                                () => reject()
                            )
                            resolve();
                        })
                    }}
                    title={null}
                    columns={columns(exampleData ? null : 'panel@cosmosid.com')}
                    data={props.samples}
                    isLoading={(props.samples.length === 0) && props.fetchingSamples}
                    options={{
                        sorting: true,
                        pageSize: 20,
                        pageSizeOptions: [10, 20, 50, 100],
                        selection: true,
                        selectionProps: rowData => {
                            if (rowData.status === 11) {
                                console.log(rowData);
                                return {}
                            }
                            else return  {
                            disabled: true,
                            checked: false
                        }}
                    }}
                    onSelectionChange={rows => {
                        console.log(rows);
                    }}
                    components={{
                        Toolbar: ({selectedRows, ...toolbarProps}) => {
                            const newSelectedRows = selectedRows.filter(rowData => rowData.status === 11);
                            if (newSelectedRows.length !== props.selected_samples.length)
                                dispatch(setSelectedSamples(newSelectedRows));
                            return <Fragment>
                                    <MTableToolbar selectedRows={newSelectedRows} {...toolbarProps}/>
                                    <Typography component={'span'} >&nbsp;Show Example Data?</Typography><Checkbox
                                        checked={exampleData}
                                        onChange={() => {
                                            console.log(exampleData)
                                            setExampleData(!exampleData)
                                        }}
                                    />
                            </Fragment>
                        }
                    }}
                />
            </Grid>
        </Grid>
            <Box pt={40}><Typography align="center" variant="h6">For Research Use Only.</Typography> </Box>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        samples: state.samples,
        selected_samples: state.selected_samples,
        download: state.download,
        uploads: state.uploads,
        fetchingSamples: state.fetchingSamples
    }
};

const mapDispatchToProps = dispatch => {
    return {
        downloadReport: sample_name => dispatch(downloadReport(sample_name)),
        toggle_sample_select: sample_name => dispatch(toggle_sample_select(sample_name))

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Samples));