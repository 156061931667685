import React, {Fragment, useState} from "react";
import { withRouter } from "react-router-dom";
import {Box, Grid, Button, TextField, CircularProgress} from "@material-ui/core";
import {ROUTES} from "../routing";
import {connect} from "react-redux";
import {downloadZipFile, sampleSearchChange} from "../actions";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { StatusBar } from '@uppy/react';
import '@uppy/core/dist/style.css'
import '@uppy/status-bar/dist/style.css'
import {connectUser} from "../connectors/user";



const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    pdf: {
        width: '80vw',
        height: '90vh'
    }
}));


function NavTabs(props) {

    const classes = useStyles();

    const BUTTONS = [
        { path: ROUTES.upload, text: 'Upload Files'},
        { path: ROUTES.samples, text: 'View Samples'}

        // { path: ROUTES.documentation, text: 'Documentation', onClick: () => window.open('https://docs.cosmosid.com/docs/introduction')}
    ];

    const [preparingZip, setPreparingZip] = useState(false);

    if (props.user.isCreditCodesManager || props.user.isCreditCodesUser) {
        BUTTONS.push({ path: ROUTES.credit_codes, text: 'Credit codes'});
    }

    function getButtonProps(path, onClick, user) {
        return {
            color: path === props.history.location.pathname ? 'secondary' : 'default',
            onClick: onClick || (() =>  { props.history.push(path) }),
            variant: 'contained',
        }
    }

    return <Box pt={4} p={2}>
        <StatusBar uppy={props.uppy}/>
        <Grid container justify="flex-start" spacing={1}>
            { BUTTONS.map(
                button => <Grid item key={button.path}><Button {...getButtonProps(button.path, button.onClick, props.user)}>{button.text}</Button></Grid>)
            }
            
            { props.history.location.pathname === ROUTES.samples ?
                <Fragment>
                <Grid item></Grid>
                    {
                        preparingZip ? <Fragment>
                                <Grid item><CircularProgress variant={"indeterminate"}/>Preparing ZIP file</Grid>
                        </Fragment> : <Fragment>
                            <Grid item><Button color="secondary" variant="contained" disabled={!props.selected_samples.length} onClick={ () => props.downloadZipFile(props.selected_samples, true, setPreparingZip)}>Download Supplementary Results</Button></Grid>
                            <Grid item><Button color="secondary" variant="contained" disabled={!props.selected_samples.length} onClick={ () => props.downloadZipFile(props.selected_samples, false, setPreparingZip)}>Download Reports</Button></Grid>
                            <Grid item><Button color="secondary" variant="contained" onClick={() => window.open(`/api/v2/samples/${props.user.id}/aggregated_csv/`)}>Download Data Aggregation</Button></Grid>
                        </Fragment>
                    }

                </Fragment> :
                null
            }

            { props.history.location.pathname === ROUTES.upload ? (
                <Fragment>
                <Grid item xs/>
                <Grid item xs={2} alignItems={"flex-end"}>
                    <div style={{border: "2px solid grey", padding: 2, textAlign: "center"}}>
                    <Typography variant={"h6"}>
                    Analysis Credits: <Typography component={"span"} variant={"h6"} color={"secondary"}>{ props.user.credits }</Typography>
                    </Typography>
                    </div>
                </Grid>
                </Fragment>
                ) :
                null
            }
        </Grid>

    </Box>
}


const mapStateToProps = state => {
    return {
        selected_samples: state.selected_samples
    }
};

const mapDispatchToProps = dispatch => {
    return {
        downloadZipFile: (selected_samples, full, setPreparingZip) => dispatch(downloadZipFile(selected_samples, full, setPreparingZip)),
        sampleSearchChange: e => dispatch(sampleSearchChange(e.currentTarget.value))
    }
};

export default withRouter(connectUser(connect(mapStateToProps, mapDispatchToProps)(NavTabs)));