import React, {useEffect, useState} from "react";
import CoveragePlot from "../components/report/CoveragePlot";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ReportHeader from "../components/report/ReportHeader";
import {CenteredLabel, Gap, Paragraph, ReportTable} from "../components/report/Common";
import CoverageStatisticsTable from "../components/report/CoverageStatisticsTable";
import axios from "axios";


export default function Report(props) {
    const urlTail = window.location.pathname.split('/report/print/')[1];
    const [userId, sampleId] = urlTail.split('/');
    const [reportData, setReportData] = useState({
        covStatData: null,
        numBases: 0,
        meanDepth: 0,
        expressControlData: null,
        varData: null,
        lenVar: 0,
        lenMut: 0,
        totalRead: 0,
        cov19Read: 0,
        cov19Perc: 0
    });
    useEffect(() => {
        axios.get(`/api/report_data/cov_stat/${urlTail}`).then(({data}) => {
            setReportData((prevState => {
                return Object.assign({}, prevState, {
                    covStatData: data.data,
                    numBases: data.data[0]["covered_bases"],
                    meanDepth: data.data[0]["avg_b_coverage"]
                })
            }))

        });
        axios.get(`/api/report_data/express_control/${urlTail}`).then(({data}) => {
            setReportData((prevState => {
                return Object.assign({}, prevState, {
                    expressControlData: data.data
                })
            }))
        });
        axios.get(`/api/report_data/var_data/${urlTail}`).then(({data}) => {
            setReportData((prevState => {
                return Object.assign({}, prevState, {
                    varData: data.data,
                    lenVar: data.len_var,
                    lenMut: data.len_mut
                })
            }))
        });
        axios.get(`/api/report_data/summary/${urlTail}`).then(({data}) => {
            setReportData((prevState => {
                return Object.assign({}, prevState, {
                    totalRead: data.data.total_read,
                    cov19Read: data.data.cov19_read,
                    cov19Perc: data.data.cov19_perc
                })
            }))
        });
    },[urlTail]);
    return (
            <Container style={{padding: '4%'}}>
                <Grid container direction={"column"} justify={"space-between"} alignItems={"flex-start"}>
                    <Grid item container xs={12}>
                        <ReportHeader userId={userId} sampleId={sampleId}/>
                    </Grid>
                    <Paragraph label='Assay'>
                        Ion AmpliSeq™ SARS-CoV-2 Research Panel. Coronaviruses have been implicated in severe acute respiratory syndromes.
                        The Ion AmpliSeq™ SARS-CoV-2 Research Panel detects SARS-CoV-2 Virus responsible for Coronavirus disease 2019 (COVID-19).
                        This report summarizes the genomic analysis of SARS-CoV-2, the causative agent of COVID-19,
                        in sample {sampleId}.bam
                    </Paragraph>
                    <Paragraph label='Summary'>
                        The sample contained {reportData.totalRead} reads, with {reportData.cov19Read} reads ({reportData.cov19Perc}) mapping to the SARS-CoV-2 reference genome.
                        Reads cover {reportData.numBases} bases of the SARS-CoV-2 genome, with a mean depth of { reportData.meanDepth.toFixed() }.
                        A total of {reportData.lenVar} variants were detected. {reportData.lenMut} out of {reportData.lenVar} variants results in amino acid changes in coding regions.
                    </Paragraph>
                    <Gap/>
                    <Grid item container xs={12}>
                        <CoverageStatisticsTable covStatData={reportData.covStatData}/>
                    </Grid>
                    <Grid item container xs={12}>
                        <CoveragePlot urlTail={urlTail}/>
                    </Grid>
                    <Gap/>
                    <Grid item container xs={12}>
                        <CenteredLabel>
                            <b>Table 2: Expression Controls</b> showing reads mapped to the targets in five housekeeping genes serving as sequencing controls.
                        </CenteredLabel>
                        <ReportTable headers={[
                            'Reference Sequence',
                            'Chromosome',
                            'Reads',
                        ]} values={
                            reportData.expressControlData ?
                                reportData.expressControlData.map(row => (
                                    [
                                        row.ref_seq,
                                        row.chromosome,
                                        row.reads
                                    ]
                                ))
                                : null
                        }/>
                    </Grid>
                    <Gap/>
                    <Grid item container xs={12}>
                        <CenteredLabel>
                            <b>Table 3: Variant Table</b>
                        </CenteredLabel>
                        { reportData.varData ?
                            <ReportTable headers={[
                                'Ref Pos',
                                'Type',
                                'Ref',
                                'Variant',
                                'Evidence',
                                'Gene',
                                'Locus Tag',
                                'Description',
                                'Effect'
                            ]} values={
                                reportData.varData.map(row => (
                                        [
                                            row.ref_pos,
                                            row.type,
                                            row.ref,
                                            row.variant,
                                            row.evidence,
                                            row.gene,
                                            row.locus_tag,
                                            row.description,
                                            row.effect
                                        ]
                                    )
                                )
                            }/>
                            : <CenteredLabel>No SNPs Detected</CenteredLabel>
                        }

                    </Grid>
                </Grid>
            </Container>
    )
}