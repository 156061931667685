import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Checkbox} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {withRouter} from "react-router-dom";
import {AFTER_REGISTRATION, register, toggle_terms} from "../actions";
import {connect} from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import {ROUTES} from "../routing";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";


export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    pdf: {
        width: '80vw',
        height: '90vh'
    },
    dialog: {
        border: '1px solid grey',
        borderRadius: '4px',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'center',
        backgroundColor: theme.palette.background.paper
    }
}));

function RegisterForm(props) {
    const classes = useStyles();
    console.log(props);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    return (
        <div className={classes.root}>
            <Grid container alignItems="center" justify="center" style={{ minHeight: '20vh'}}>
                <Grid item xs={7}>
                    <Typography variant="h5" style={{ minHeight: '20vh', display: "none" }}>
                        The CosmosID SARS-CoV-2 Genome Analysis Portal automates the analysis of data generated with the Ion AmpliSeq™ SARS-CoV-2 Research Panel by Thermo Fisher
                    </Typography>
                </Grid>
            </Grid>
        <Grid container alignItems="center" justify="center" style={{ minHeight: '20vh' }}>
            <Grid item xs={4}>
                <Paper className={classes.paper}>
                    <form name="register-form">
                    <Grid container alignItems="stretch" justify="center" direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography variant="h4" color="textPrimary">Registration</Typography>
                        </Grid>
                        <Grid item xs>
                            <TextField name="email" fullWidth label="Work email" required={ true }/>
                        </Grid>
                        <Grid item xs>
                            <TextField name="password" fullWidth label="Password" required={ true } type="password"/>
                        </Grid>
                        <Grid item xs>
                            <TextField name="password-confirm" fullWidth label="Confirm password" required={ true } type="password"/>
                        </Grid>
                        <Grid item xs>
                            <Divider  light/>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2" align="left"><Checkbox name="accept-terms" required/>
                            I agree with
                            <Typography component="span" variant="body2" align="left" color="primary" onClick={ props.termsLinkOnClick }> Terms of use</Typography></Typography>
                        </Grid>
                    </Grid>
                    </form>
                    <Grid container alignItems="flex-end" justify="center" direction="column" spacing={2}>
                        <Grid item xs>
                            <Button variant="contained" color="primary" onClick={ () => props.registerButtonOnClick({
                                setOpenSnackbar, setSnackbarText
                            }) }>
                                Register
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    open={openSnackbar}
                    autoHideDuration={5000}
                    onClose={() => setOpenSnackbar(false)}
                    message={snackbarText}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpenSnackbar(false)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }/>
            </Grid>
        </Grid>

                <Backdrop className={classes.backdrop} open={ props.terms } onClick={ props.termsLinkOnClick }>
                    <Paper variant={"elevation"} elevation={3}>
                        <Container className={classes.pdf}>
                <embed src="/CosmosID_Terms_of_Service_8_30_2016_Final.pdf#toolbar=0&statusbar=0&navpanes=0&scrollbar=0&view=fitH" type="application/pdf" width="100%" height="100%" />
                        </Container></Paper>
                    </Backdrop>
            <Dialog  open={props.afterRegistration} transition>
                <div className={classes.dialog}>
                <Typography variant={"h5"}>Welcome to your new account on the<br/>
                    SARS-CoV-2 Genome Analysis Portal</Typography>
                <div style={{height: '15px'}}/>
                <Typography variant={"h6"}>Before you can sign in you need to activate your account.<br/>
                    An activation link in has been sent to you by email.<br/>
                    To obtain analysis credits please contact us via email to<br/>
                    <Typography color={"secondary"} variant={"h6"} onClick={ () => {window.location = "mailto:sales@cosmosid.com"}}>sales@cosmosid.com</Typography></Typography>
                    <div style={{height: '15px'}}/>
                    <Button variant={"contained"} onClick={ () => props.afterRegistrationOnClick(props.history)} color={"secondary"}>Continue</Button>
                </div>
            </Dialog>
                </div>

    )
}

const mapStateToProps = state => {
    return {
        terms: state.terms,
        afterRegistration: state.afterRegistration
    }
};

const mapDispatchToProps = dispatch => {
    return {
        registerButtonOnClick: (snackbarControls) => {
            const form = document.querySelector('form[name=register-form]');
            if (form['password-confirm'].value !== form['password'].value) {
                snackbarControls.setSnackbarText('Passwords must be the same');
                snackbarControls.setOpenSnackbar(true);
                return;
            }
            if (form['password'].value === '') {
                snackbarControls.setSnackbarText('Passwords can not be empty');
                snackbarControls.setOpenSnackbar(true);
                return;
            }
            if (!form['accept-terms'].checked) {
                snackbarControls.setSnackbarText('Terms of use must be accepted');
                snackbarControls.setOpenSnackbar(true);
                return;
            }
            dispatch(register(form));
        },
        termsLinkOnClick: () => {
            dispatch(toggle_terms())
        },
        afterRegistrationOnClick: (history) => {
            dispatch({type: AFTER_REGISTRATION});
            history.push(ROUTES.login);
        }

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterForm));
