import React, {forwardRef, Fragment, useEffect, useState} from "react";
import axios from 'axios';
import {withRouter, NavLink} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {get_users, delete_account, get_samples} from "../actions";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import MaterialTable from "material-table";


import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AdminSamples from "./AdminSamples";
import Dialog from "@material-ui/core/Dialog";
import TopBar from "../components/TopBar";

const topBarContext = {
    extraText: null,
    buttonText: "Log out",
    buttonHref: "/logout"
};

export const adminPageContext = {
    topBarContext
};


function UserCreditsField(props) {
    const [invalid, setInvalid] = useState(false);
    const [pendingUpdate, setPendingUpdate] = useState(null);
    const [value, setValue] = useState(props.value);
    return (
        <TextField error={invalid} onChange={(e) => props.changeCredit(props.user._id, e.currentTarget.value, setInvalid, setPendingUpdate, pendingUpdate, setValue)} value={value}/>
    )
}


function Admin(props) {
    const [focusedUser, setFocusedUser] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => dispatch(get_users()),[]);
    let timeoutsTmp = {};
    props.users.forEach(user => timeoutsTmp[user._id] = [false, null]);

    const columns = [
        {
            title: 'E-mail',
            field: 'email',
            editable: 'never'
        },
        {
            title: 'Credits',
            field: 'user_profile.credits'
        },
        {
            title: 'Created On',
            field: 'createdAt',
            editable: "never",
            render: rowData => new Date(rowData.date_joined).toLocaleDateString()
        }
    ]

    const detailColumns = email => [
        {
            title: 'Batch Name',
            field: 'batch_name'
        },
        {
            title: 'Sample Name',
            field: 'sample_name',
            render: rowData => (rowData.report_available ?
                <NavLink
                    to={ window.location.pathname }
                    onClick={() => window.open(`/api/samples/report-${rowData.sample_name}?email=${email}`)}
                >
                    {rowData.sample_name}
                </NavLink> :
                rowData.sample_name
                )
        },
        {
            title: 'Upload Date',
            field: 'upload_date',
            render: rowData => new Date(rowData.upload_date).toLocaleDateString()
        },
        {
            title: 'File Size',
            field: 'size'
        },
        {
            title: 'Status',
            field: 'status'
        },
        {
            title: 'Credits Used',
            render: rowData => rowData.status === 'Analysis complete' ? 1 : 0
        },
        {
            title: 'Credits On Hold',
            render: rowData => rowData.status === 'Analysis complete' ? 0 : 1
        }
    ]

    return (
        <Container>
            <Typography variant="h5" align="center">User Management</Typography>
            <Dialog fullScreen open={focusedUser} >
                {focusedUser ?
                    <Fragment>
                        <TopBar/>
                        <Tooltip title='Close User Details' style={{width: '56px', height: '56px'}}>
                            <Fab color={"primary"} onClick={() => {setFocusedUser(null)}}>
                                <CancelOutlinedIcon/>
                            </Fab>
                        </Tooltip>
                    <h4 style={{textAlign: "center"}}><b>User:</b> {focusedUser.email}</h4>
                        <h4 style={{textAlign: "center"}}><b>Credits: </b> {focusedUser.credits}</h4>
                    <AdminSamples email={focusedUser.email}/>
                    </Fragment> :
                    null
                }

            </Dialog>
            <MaterialTable
                title={'User Management'}
                columns={columns}
                data={props.users}
                editable={{
                    //isEditable: rowData => true,
                    //isDeletable: rowData => true,
                    onRowDelete: oldData => new Promise((resolve, reject) => {
                        console.log(oldData);
                        dispatch(delete_account(oldData.email, resolve, reject))
                    }),
                    onRowUpdate: (newData, oldData) => new Promise(resolve => {
                        dispatch(changeCredit(oldData.id, parseInt(newData.user_profile.credits)));
                        resolve();
                    })

                }}
                options={{
                    actionsColumnIndex: -1
                }}
                localization={{
                    body: {
                        editRow: {
                            deleteText: <Typography color={"secondary"}>Are you sure you want to delete this user's account?</Typography>
                        }
                    }
                }}
                detailPanel={
                    rowData => <MaterialTable
                        title={`Samples of ${rowData.email}`}
                        columns={detailColumns(rowData.email)}
                        data={query => new Promise((resolve, reject) => {
                            console.log(query);
                            dispatch(get_samples(rowData.email, resolve, reject, query))
                        })}
                        options={{paging: false}}
                    />
                }
            />
        </Container>
    );
}


const mapStateToProps = state => {
    return { users: state.admin.users }
};


export const CREDIT_CHANGED = 'CREDIT_CHANGED';


const changeCredit = (userId, raise) => dispatch => axios.patch(
            `/api/v2/users/${userId}/`,
            {
                id: userId,
                user_profile: {
                    credits: raise
                }
            }
        ).then(
            setTimeout(() => dispatch(get_users()), 500 )
        );

const mapDispatchToProps = dispatch => {
    return {
    deleteButtonOnClick: user => {

    },
        changeCredit: (userId, value, setError, setPendingUpdate, pendingUpdate, setValue) => {
            setValue(value);
            if (pendingUpdate) {
                clearTimeout(pendingUpdate);
                setPendingUpdate(null);
            }

            if (!Number(value)) {
                setError(true);
            }
            else {
                setError(false);
                setPendingUpdate(setTimeout(() => {
                    dispatch(changeCredit(userId, parseInt(value)));
                }, 2000));
            }
        }
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));