import React, {Fragment, useState, useEffect} from "react";
import Dropzone from "react-dropzone";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {authenticated, SHOW_BLOCKED, upload} from "../actions"
import {connect, useDispatch} from "react-redux";
import {Box, Dialog, Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import {useStyles} from "../components/RegisterForm";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import {ROUTES} from "../routing";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core//MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import axios from "axios";
import {connectUser} from "../connectors/user";

const topBarContext = {
    extraText: null,
    buttonText: "Log out",
    buttonHref: "/logout"
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#999999',
    borderStyle: 'dashed',
    backgroundColor: '#eeeeee',
    color: '#bdbdbd',
    outline: 'none',
    height: '48vh',
    transition: 'border .24s ease-in-out'
};

function UploadForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [batchNumber, setBatchNumber] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const [articVersion, setArticVersion] = useState("");
    const artic = props.user.analysisType.toLowerCase() === 'artic';
    const allowedFiles = props.user.analysisType === 'Thermofisher' ?
        {
            label: 'Supported formats and Extension names: .fastq.gz, .fastq and .bam',
            extensions : ['.bam', '.fastq', '.fastq.gz']
        } :
        {
            label: 'Supported Formats: Single-End and Paired End Illumina Fastqs in gzipped forrmat only',
            extensions: ['.fastq.gz']
        };
    useEffect(() => {
        if (Object.entries(props.uploads).length) {
            window.onbeforeunload = function() {
                return "Upload is in progress. Are you sure you want to cancel it?";
            }
        }
        else window.onbeforeunload = () => null;
    }, [props.uploads])
    return (
        <Fragment>
        <Grid container>
            <Grid item xs>
                <Box pl={'40%'} pr={'40%'} pt={2} pb={2}>
                    <TextField error={openSnackbar && batchNumber === ''} value={batchNumber} name='batchName' fullWidth label="Batch name" required={ true } onChange={event => setBatchNumber(event.target.value)}/>
                    {
                        artic ? <FormControl
                            fullWidth
                            required
                            error={openSnackbar && articVersion === ''}
                        >
                            <InputLabel id="select-required-label">Pipeline version</InputLabel>
                            <Select
                                value={articVersion}
                                labelId={"select-required-label"}
                                onChange={event => setArticVersion(event.target.value)}
                            >
                                <MenuItem value={"v3"}>Artic V3</MenuItem>
                                <MenuItem value={"v4.1"}>Artic V4.1</MenuItem>
                                {
                                    process.env.REACT_APP_ENABLE_ARTIC_NO_SUBSAMPLING ?
                                        <MenuItem value={"v4.1-no-subsampling"}>Artic V4.1 w/o subsampling</MenuItem> :
                                        null
                                }
                            </Select>
                        </FormControl> :
                        null
                    }
                </Box>
        <Box pl={2} pr={2} onClick={() => {
            if (openSnackbar) return;
            if (batchNumber === '') {
                setOpenSnackbar(true);
                setSnackbarText('Please provide batch name above')
            }
            else if (artic && articVersion === '') {
                setOpenSnackbar(true);
                setSnackbarText('Please select pipeline version above')
            }
        }}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarText}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpenSnackbar(false)}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }/>
        <Dropzone
            disabled={(batchNumber === '' || (artic && articVersion === ''))}
            onDropAccepted={ (files) => props.onAcceptedDrop(files, props.user.credits, props.history, batchNumber, props.uppy, props.user.email, articVersion) }
            accept={allowedFiles.extensions}
        >
            {({getRootProps, getInputProps}) => (
                <section>
                    <div {...getRootProps()} style={baseStyle}>
                        <input {...getInputProps()} />
                        <Typography variant="h5" color="textPrimary" align="center" style={{marginTop: '15vh'}}>
                            Click to open file browser<br/>or<br/>Drop files to upload
                        </Typography>
                        <Typography variant="body1" color="textPrimary" align="center">
                            (For paired-end data
                            <Typography color="secondary" component="span"> drop all files at the same time</Typography>)
                        </Typography>
                        <Typography variant="body1" align="center" color="textPrimary">
                            { allowedFiles.label }
                        </Typography>
                    </div>
                </section>
            )}
        </Dropzone>

        </Box>
            <Box><Typography align="center" variant="h6">Please upload de-identified data - do not share personally identifiable information.</Typography> </Box>
            {/*{ Object.entries(props.uploads).length ?
                <Grid container direction="column" justify="space-between" alignItems="center">
                        { Object.entries(props.uploads).map( upload => {
                            return (
                                <Grid item key={upload[0]}>
                                    <Grid container spacing={1}  alignItems="center" justify="space-between">
                                        <Grid item>
                                            <Typography>{upload[0]}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <div style={{alignItems: 'center', alignContent: 'stretch', display: 'flex'}}>
                                                <span style={{paddingRight: '5px'}}>Sample Upload {upload[1]}%</span> <CircularProgress variant="static" color={"secondary"} value={upload[1]}/>
                                            </div>
                                            <CircularProgress variant="static" color={"secondary"} value={upload[1]}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            )
                        }
                </Grid> :
                null
            }*/}

            <Backdrop className={classes.backdrop} open={ false }>
                <Paper variant={"elevation"} elevation={3}>
                    <Typography variant={"h5"}>You need credits to analyze your samples, please contact CosmosID at <Link to="mailto:info@cosmosid.com">info@cosmosid.com</Link> or +1 703 995 9879</Typography>
                </Paper>
            </Backdrop>
            </Grid>
            <Grid item xs={2} p={2}>
                {/*<Box pr={2}>
                <Typography align={"center"}>To purchase credits please contact:</Typography>
                <Typography color={"secondary"} align={"center"} onClick={ () => {window.location = 'mailto:sales@cosmosid.com'} }>sales@cosmosid.com</Typography>
                </Box>*/}
                </Grid>
        </Grid>
        <Box pt={'20vh'}><Typography align="center" variant="h6">For Research Use Only.</Typography> </Box>
            <Dialog open={ props.showBlocked }>
                <div className={classes.dialog}>
                <Typography variant={"h5"}>Your account is out of analysis credits.</Typography>
                <Typography variant={"h6"}>Please purchase more credits and then reupload your samples</Typography>
                <Button variant={"contained"} onClick={props.disableBlocked} color={"secondary"}>Continue</Button>
                </div>
            </Dialog>
        </Fragment>
    )
}

export const uploadPageContext = {
    topBarContext
};

const mapStateToProps = state => {
    return {
        uploads: state.uploads,
        showBlocked: state.showBlocked,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAcceptedDrop: (files, credits, history, batchNumber, uppy, userEmail, articVersion) => {
            const expected_files_count = files.length;
            if (files.length > credits) dispatch({type: SHOW_BLOCKED});
            else {
                files.forEach(file => {
                    dispatch(upload(file, batchNumber, expected_files_count, uppy, userEmail, articVersion))
                })
                history.push(ROUTES.samples);
            }
        },
        disableBlocked: () => dispatch({type: SHOW_BLOCKED})
    }
};

export default withRouter(connectUser(connect(mapStateToProps, mapDispatchToProps)(UploadForm)));
