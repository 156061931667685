import axios from 'axios';
import {findDOMNode} from "react-dom";

export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATED = 'AUTHENTICATED';
export const END_SESSION = 'END_SESSION';
export const USERS_RETRIEVED = 'USERS_RETRIEVED';
export const REGISTER = 'REGISTER';
export const TOGGLE_TERMS = 'TOGGLE_TERMS';

console.log(process.env);
const TOKEN_REFRESH_INTERVAL = process.env.REACT_APP_TOKEN_REFRESH_INTERVAL || 4;

export function authenticate(email, password, history, snackbarSetters) {
    return function(dispatch) {
        return axios.post("/api/v2/auth/token/", {
            email,
            password
        }).then(
            ({ data }) => dispatch(authenticated(data.access, data.refresh, dispatch))
        ).catch(error => {
            console.log(error)
            snackbarSetters.setSnackbarText('Invalid credentials');
            snackbarSetters.setOpenSnackbar(true);
        });
    };
}

export const refreshToken = () => (dispatch, getState) => {
    const jwt_refresh = getState().jwt_refresh;
    axios.post("/api/v2/auth/token/refresh/", {
        refresh: jwt_refresh
    }).then(
        ({data}) => dispatch(authenticated(data.access, jwt_refresh, dispatch))
    ).catch(
        error => dispatch(end_session())
    )
}

export const OWN_USER_RETRIEVED = 'OWN_USER_RETRIEVED';

export const getOwnUser = () => dispatch => (
    axios.get(
        `/api/v2/users/self/`
    ).then(({data}) =>
        dispatch({
            type: OWN_USER_RETRIEVED,
            user: data
        })
    )
);

export function authenticated(jwt_access, jwt_refresh, dispatch) {
    axios.defaults.headers.authorization = `Bearer ${jwt_access}`;
    localStorage.setItem('jwt_refresh', jwt_refresh);
    setTimeout(() => dispatch(refreshToken()), TOKEN_REFRESH_INTERVAL * 60 * 1000);
    return {
        type: AUTHENTICATED,
        jwt_access,
        jwt_refresh
    }
}

export function end_session() {
    localStorage.clear();
    delete axios.defaults.headers.authorization;
    return {
        type: END_SESSION
    }
}

export function users_retrieved(users) {
    return {
        type: USERS_RETRIEVED,
        users
    }
}

export function get_users() {
    return function(dispatch){
        axios.get("/api/v2/users/").then(
            ({data}) => {
                dispatch(users_retrieved(data));
            }
        )
    }
}

export function register(form) {
    const postData = {
        "email": form.querySelector('[name=email]').value,
        "password": form.querySelector('[name=password]').value,
        "password-confirm": form.querySelector('[name=password-confirm]').value
    };
    console.debug(postData);
    return function (dispatch) {
        axios.post(
            "/api/v1/register/",
            postData
        ).then(
            ({data}) => {
                console.log(data);
                dispatch({type: AFTER_REGISTRATION})
            }
        )
    }

}


export const redeemCode = (code, errback) => (dispatch, getState) => {
    const state = getState();
    axios.post(
        `/api/v2/users/${state.user.id}/credit-codes/`,
        {
            code,
            redeemed_by: state.user.id
        }
    ).then(
        () => dispatch(getOwnUser())
    ).catch(
        (err) => errback(err)
    )
};


export const UPLOAD_START = 'UPLOAD_START';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_DONE = 'UPLOAD_DONE';


function upload_start(fileInfo) {
    console.log('UPLOAD_START', fileInfo)
    return {
        type: UPLOAD_START,
        fileInfo
    }
}

export const upload_progress = (fileId, progress) => (dispatch, getState) => {
    const uploads = getState().uploads;
    const upload = uploads[fileId];
    if (!upload) return;
    dispatch({
        type: UPLOAD_PROGRESS,
        fileId,
        batchName: upload.batchName,
        sampleId: upload.sampleId,
        sampleName: upload.sampleName,
        inputFileId: upload.input_file.id,
        fileSize: upload.fileSize,
        submitted_at: upload.submitted_at,
        progress
    })
}

function upload_done(fileId) {
    return {
        type: UPLOAD_DONE,
        fileId: fileId
    }
}

export const finalizeUpload = fileId => (dispatch, getState) => {
    const fileInfo = getState().uploads[fileId];
    axios.patch(
        `/api/v2/samples/input-files/${fileInfo.input_file.id}/`,
        {
            uploaded: true
        }
    ).then(
        ({data}) => {
            setTimeout(() => dispatch(upload_done(fileId)), 2000);
            console.log(data);
        }
    )
}

const bucketPrefix = process.env.NODE_S3_PREFIX ? process.env.NODE_S3_PREFIX : 'ampliseq-2-test';


export function upload(file, batchNumber, expected_files_count, uppy, userEmail, articVersion) {
    return function (dispatch, getState) {
        const user = getState().user
        axios.post(
            `/api/v2/users/${user.id}/batches/`,
            {
                name: batchNumber,
                input_files: [{name: file.name, size: file.size}],
                artic_version: articVersion
            }
            ).then(({data}) => {
                const sample = data.samples.filter(
                    sample => sample.input_files.filter(
                        input_file => input_file.name === file.name
                    ).length > 0
                )[0];
                const input_file = sample.input_files.filter(
                    input_file => input_file.name === file.name
                )[0];
                console.log(input_file);
                const fileId = uppy.addFile({
                    name: input_file.s3_bucket_path,
                    data: file,
                    source: 'Local'
                })
                const fileInfo = Object.assign(
                    {
                        fileId,
                        input_file,
                        sampleName: sample.name,
                        batchName: batchNumber,
                        sampleId: sample.id,
                        progress: 0,
                        fileSize: input_file.size,
                        submitted_at: input_file.submitted_at
                    },
                    file
                );
                dispatch(upload_start(fileInfo));
        }).catch(
            (err) => alert(`File ${file.name} already exists in batch ${batchNumber} in your account, please check and upload a different file`)
        )


    }

}

export const FETCHING_SAMPLES = 'FETCHING_SAMPLES';

export function get_samples(user_email, resolve, reject, query) {
    return function(dispatch, getState) {
        const user = getState().user;
        let url = `/api/v2/users/${user.id}/batches/`;
        if (user_email) url = `/api/v2/users/0/batches/`;
        dispatch({type: FETCHING_SAMPLES});
        axios.get(
            url,
        ).then(
            ({data}) => {
                if (data.length === 0) {
                    dispatch(samples_fetched([]));
                    if (resolve) resolve({data: []});
                    return;
                }
                const samples = data.map(
                    batch => batch.samples.map(
                        sample => Object.assign(
                            {
                                batchName: batch.name
                            },
                            sample
                        )
                    )
                ).reduce(
                    (total, currentValue) => total.concat(currentValue)
                ).filter(
                    sample => sample.input_files.length > 0
                ).filter(
                    sample => !sample.deleted
                );
                console.log(samples)
                dispatch(samples_fetched(samples));
                if (resolve) resolve({data: samples});
            }
        ).catch(() => {
            if (reject) reject();
        })
    }
}

export function delete_account(email, resolve) {
    const url = `/api/delete_account`
    return function (dispatch) {
        axios.post(
            url,
            {
                email
            }
        ).then(
            () => {
                dispatch(get_users());
                resolve();    
            }
            
        )
    }
}

export const SAMPLES_FETCHED = 'SAMPLES_FETCHED';

export function samples_fetched(samples) {
    return {
        type: SAMPLES_FETCHED,
        samples: samples
    }
}

export const SAMPLE_DOWNLOAD_REPORT = 'SAMPLE_DOWNLOAD_REPORT';

export function downloadReport(sample_name, admin=false, email=null) {
    //return {
    //    type: SAMPLE_DOWNLOAD_REPORT,
    //    url: BACKEND_URL + '/api/samples/report-' + sample_name
    //};
    return dispatch => window.location = '/api/samples/report-' + sample_name + (email ? '?email=' + email : '');
}

export function toggle_terms() {
    return {
        type: TOGGLE_TERMS
    }
}

export const TOGGLE_SAMPLE_SELECT = 'TOGGLE_SAMPLE_SELECT';

export function toggle_sample_select(sample_name) {
    return {
        type: TOGGLE_SAMPLE_SELECT,
        sample_name
    }
}

export const SET_SELECTED_SAMPLES = 'SET_SELECTED_SAMPLES';

export function setSelectedSamples(samples) {
    return {
        type: SET_SELECTED_SAMPLES,
        samples
    }
}

export function downloadZipFile(selected_samples, full, setPreparingZip) {
    const samples = JSON.stringify(selected_samples);
    const checkIfExists = fname => {
        setPreparingZip(true);
        axios.head(
            `/api/v2/samples/zip/${fname}/ready/`
        ).then(() => {
            setPreparingZip(false);
            window.location = `/api/v2/samples/zip/${fname}/`;
        }
        ).catch(err => {setTimeout(checkIfExists, 10000, fname)})
    }
    return (dispatch, getState) => {
        const user = getState().user;
        axios.post(
            `/api/v2/samples/zip/${samples}/${full}/${user.id}/`,
            {}
        ).then(
            ({data}) => {
                checkIfExists(data.file)
            }
        )
    };
}

export const SAMPLE_SEARCH_CHANGE = 'SAMPLE_SEARCH_CHANGE';

export function sampleSearchChange(value) {
    return {
        type: SAMPLE_SEARCH_CHANGE,
        value: value
    }
}

export const AFTER_REGISTRATION = 'AFTER_REGISTRATION';

export const SHOW_BLOCKED = 'SHOW_BLOCKED';