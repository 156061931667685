import Uppy from "@uppy/core";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";


//const companionHost = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3020'

const S3Provider = process.env.NODE_ENV === 'production' ?
    AwsS3Multipart :
    AwsS3;


const getUppy = () => {
    const uppy = new Uppy({
        'autoProceed': true
    }).use(S3Provider, {
        limit: 4,
        timeout: ms('1 minute'),
        retryDelays: [0, 1000, 3000, 5000],
        companionUrl: '/companion/'
        //companionUrl: `${companionHost}/companion/`
    });
    uppy.on('upload-progress', (file, progress) => {
        // file: { id, name, type, ... }
        // progress: { uploader, bytesUploaded, bytesTotal }
        console.log(file.id, progress.bytesUploaded, progress.bytesTotal)
    })
    return uppy;
}


export default getUppy;