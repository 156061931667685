import React, {Fragment, useEffect} from "react";
import echarts from 'echarts';
import axios from "axios";
import colorInterpolate from 'color-interpolate';
import {round} from "echarts/src/util/number";
import Grid from "@material-ui/core/Grid";
import {CenteredLabel, SimpleLabel} from "./Common";


export default function Report(props) {

    useEffect(() => {
        const plotContainer = document.querySelector('div#plot');
        const plot = echarts.init(plotContainer);
        axios.get(`/api/report_data/plot/${props.urlTail}`).then(({data}) => {
            const fontSize = 20;
            let data_src = data.data;
            let min_val = data.min;
            let max_val = data.max;
            const option = {
                xAxis: {
                    scale: false,
                    name: "Genomic Coordinate",
                    nameLocation: "middle",
                    nameGap: 30,
                    nameTextStyle: {
                        fontSize: fontSize
                    },
                    splitLine: false
                },
                yAxis: {
                    scale: false,
                    name: "Depth",
                    nameTextStyle: {
                        fontSize: fontSize
                    },
                    splitLine: false
                },
                series: [
                    {
                        symbolSize: 1,
                        data: data_src,
                        type: "line",
                        large: true,
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: "#24b7f2"
                                },
                                {
                                    offset: 1,
                                    color: "#f2c31a"
                                }
                            ])
                        }
                    }
                ]
            };
            option.series[0].lineStyle = Object.assign({}, option.series[0].areaStyle);
            option.series[0].lineStyle.opacity = 0.05;
            option.series[0].itemStyle = Object.assign({}, option.series[0].areaStyle);
            option.series[0].itemStyle.opacity = 0.05;
            plot.setOption(option);
            const canvas = plotContainer.querySelector('canvas');
            const targetPlot = document.querySelector('div#targetPlot img');
            targetPlot.src = canvas.toDataURL();

            const mapCanvas = document.querySelector('div#map canvas');
            const ctx = mapCanvas.getContext('2d');
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            const colormap = colorInterpolate(['#24b7f2', '#f2c31a']);
            const valueMap = [];
            data_src.forEach((item) => {
                let found = false;
                for (let i = 0; i < valueMap.length; i++) {
                    if (valueMap[i].value === item[1]) {
                        valueMap[i].count += 1;
                        found = true;
                        break;
                    }
                }
                if (!found) valueMap.push({value: item[1], count: 1})
            });
            let sum1 = 0;
            let sum2 = 0;
            for (let i = 0; i < valueMap.length; i++) {
                sum1 = round(sum1 + parseInt(valueMap[i].value), 10);
                sum2 = round(sum2 + parseInt(valueMap[i].count), 10);
            }
            //console.log(sum1, sum2);
            const ratio1 = round(1 / sum1, 10);
            const ratio2 = round(1 / sum2, 10);
            //console.log(ratio1, ratio2);
            let [lastOffset, lastColor] = [0, 0];
            gradient.addColorStop(lastOffset, colormap(lastColor));
            for (let i = 0; i < valueMap.length; i++) {
                lastOffset += ratio2 * parseInt(valueMap[i].count);
                lastColor += ratio1 * parseInt(valueMap[i].value);
                //console.log(valueMap[i].value, lastOffset, lastColor, colormap(lastColor));
                if (lastColor > 1 || lastOffset > 1) break;
                gradient.addColorStop(lastOffset, colormap(lastColor));
            }
            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, 200, 150);
            const targetMap = document.querySelector('div#targetMap img');
            targetMap.src = mapCanvas.toDataURL();
        })
    }, [props.urlTail]);

    return (
        <Fragment>
            <div style={{'width': '15in', 'height': '4in', 'display': 'none'}} id={"plot"}/>
            <div style={{'width': '2in', 'height': '4in', 'display': 'none'}} id={"map"}>
                <canvas/>
            </div>
            <Grid item container justify={"space-between"} alignItems={"center"}>
                <Grid xs={11} item id={'targetPlot'}>
                    <img alt={'coverage plot'} style={{'width': '100%', }}/>
                </Grid>
                <Grid xs={1} item id={'targetMap'} style={{"height": "100%", "paddingTop": "5%", "paddingBottom": "5%"}}>
                    <SimpleLabel><b>Depth H</b></SimpleLabel>
                    <div style={{"height": "100%"}}><img alt={'coverage map'} style={{'width': '50%', 'height': '100%'}}/></div>
                    <SimpleLabel><b>Depth L</b></SimpleLabel>
                </Grid>
            </Grid>
            <CenteredLabel>
                <b>Figure 1: Coverage Plot</b> showing the depth of sequencing reads along the SARS-CoV-2 genome.
            </CenteredLabel>
        </Fragment>
    )
}