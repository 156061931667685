import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {TableCell} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import Divider from "@material-ui/core/Divider";


const normalFontSize = '7pt';
const largeFontSize = '10pt';

const useStyles = makeStyles(theme => ({
    simpleLabel: {
        fontSize: normalFontSize,
        display: 'block'
    },
    centeredLabelContainer: {
        textAlign: 'center',
        fontSize: normalFontSize
    },
    paragraphLabel: {
        fontSize: largeFontSize,
        fontWeight: 'bold',
        margin: '1% 0 0 0'
    },
    paragraphSpan: {
        textAlign: 'justify',
        display: 'block',
        fontSize: normalFontSize
    },
    table: {
        margin: '1% 5%',
        width: '-webkit-fill-available'
    },
    tableHeadCell: {
        fontSize: normalFontSize,
        lineHeight: 'unset',
        color: 'unset',
        padding: '1pt 3pt',
        border: '1px solid #aaaaaa !important',
        fontWeight: 'bold !important',
        backgroundColor: '#cccccc !important'
    },
    tableCell: {
        fontSize: normalFontSize,
        lineHeight: 'unset',
        color: 'unset',
        padding: '1pt 3pt',
        border: '1px solid #aaaaaa !important'
    }
}));

export function SimpleLabel(props) {
    const classes = useStyles();
    return (
        <span className={classes.simpleLabel}>{props.children}</span>
    )
}

export function CenteredLabel(props) {
    const finalProps = Object.assign({xs: 12}, props);
    const classes = useStyles();
    return (
        <Grid item {...finalProps}>
            <Container className={classes.centeredLabelContainer}>
                { props.children }
            </Container>
        </Grid>
    )
}

export function Paragraph(props) {
    const finalProps = Object.assign({xs: 12}, props);
    const classes = useStyles();
    return (
        <Grid item {...finalProps}>
            <p className={classes.paragraphLabel}>{props.label}</p>
            <span className={classes.paragraphSpan}>
                { props.children }
            </span>
        </Grid>
    )
}

export function ReportTable(props) {
    const finalProps = Object.assign({xs: 12}, props);
    const classes = useStyles();
    return (
        <Grid item {...finalProps}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        { props.headers.map(
                            header => (
                                <TableCell key={header} className={classes.tableHeadCell}>{header}</TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.values ?
                            props.values.map(row => (
                                <TableRow key={row}>
                                    {row.map(value => (
                                        <TableCell className={classes.tableCell} key={row + value}>{value}</TableCell>
                                    ))}
                                </TableRow>
                            ))
                        : null
                    }
                </TableBody>
            </Table>
        </Grid>
    );
}

export function Gap(props) {
    return (
        <Divider style={{height: '8pt'}}/>
    )
}